const colors = `
  --color-purple: #4d16b2;
  --color-purple-hover: #4d16b2;
  --color-purple-medium: #e4bbde;
  --color-purple-light: #f7eef3;
  --color-black: #1f1f1f;
  --color-grey: #888888;
  --color-grey-light: #cccccc;
  --color-grey-light-light: #f2f2f2;
  --color-white: #ffffff;
  --color-blue-dark-dark: #332ab3;
  --color-blue-dark: #2c50e0;
  --color-blue-light: #d9eeff;
  --color-red: #ec433c;
  --color-green: #4acc74;
  
  --color-lylli-purple-dark: #604077;
  --color-lylli-purple-light: #D1B7E4;
  --color-lylli-pink: #FAB99C;
  --color-lylli-green: #92DEBF;
  --color-lylli-blue: #A2DAED;
  --color-lylli-dark-blue: #16617A;
  --color-lylli-blue-30: #E3F4FA;
  --color-lylli-yellow: #E2DA85;
  --color-lylli-brown: #C78B70;
  --color-lylli-black: #000000;
  --color-lylli-gray: #F5F5F7;
  --color-lylli-white: #FFFFFF;
  --color-lylli-books-bg: rgba(226, 218, 133, 0.2);
  --color-lylli-almond: #FBEBCA;
`;

const parsedColors = colors
  .split('\n')
  .filter(Boolean)
  .map((line) => line.trim().replace('--color-', '').replace(';', ''))
  .map((line) => line.split(': '))
  .reduce((colors, [name, value]) => ({ ...colors, [name]: value }), {});

export const Colors = ({  ...parsedColors });
